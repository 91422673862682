import * as React from "react";
import { usePostGiftJob } from "../../../../hooks/api/admin/usePostGiftJob";
import { PrimaryButton } from "../../../atoms/Button/PrimaryButton";
import { Spinner } from "../../../atoms/Spinner/Spinner";
import { TextInput } from "../../../atoms/TextInput/TextInput";
import { AdminNavigation } from "../AdminNavigation";

const objectIdLength = 24;

export const GiftJobPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [userId, setUserId] = React.useState<string>("");

    const postGift = usePostGiftJob(userId, () => {
        setUserId("");
    });
    const onUserIdChanged = React.useCallback(
        (_, value: string) => {
            setUserId(value);
        },
        [setUserId]
    );
    const onGiftJob = React.useCallback(() => {
        postGift.mutation.mutate();
    }, [postGift.mutation]);

    const isConfigButtonDisabled = userId.length !== objectIdLength;
    if (postGift.mutation.isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <AdminNavigation />
            <TextInput label="Gift headjob to userid" placeholder="userId" value={userId} onChange={onUserIdChanged} />
            <PrimaryButton disabled={isConfigButtonDisabled} onClick={onGiftJob}>
                Gift a job
            </PrimaryButton>
        </>
    );
};
